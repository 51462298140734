<template>
  <v-container>
    <div>
      <router-view></router-view>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({}),

  methods: {},
};
</script>

<style scoped>
.active {
  color: red;
}
</style>
